import React, { useRef, useState } from 'react'
import { Link } from 'gatsby'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

import Logo from '../../assets/logo.inline.svg'
import { Button } from '../button'

import HamburgerMenu from './hamburger-menu'
import HamburgerMenuIconFixed from './hamburger-menu-icon'

import './style.scss'

export default function Header() {
	const [menuVisible, setMenuVisible] = useState(false)

	const downloadLinkRef = useRef(null)
	return (
		<>
			<div className="shadow-fancy-1-md px-container-gap-sm lg:px-container-gap w-full max-w-full h-20 bg-white fixed top-0 left-0 right-0 z-10">
				<nav className="h-full w-full container px-0">
					<ul className="h-full flex flex-row items-center justify-between">
						<div className="left-section hidden lg:flex lg:flex-row">
							<li className="link mr-8">
								<Link to="/">Home</Link>
							</li>
							<li className="link mr-8">
								<AnchorLink to="/#features" title="Features" />
							</li>
							<li className="link mr-8">
								<Link to="/faq">FAQs</Link>
							</li>
							<li className="link">
								<AnchorLink to="/#feedback" title="Get in touch" />
							</li>
						</div>
						<li className="branding">
							<Link to="/">
								<Logo className="h-11 w-auto" />
							</Link>
						</li>
						<div className="right-section hidden lg:flex lg:flex-row lg:items-center">
							<li className="mr-8">
								<Button
									variant="fancy"
									size="small"
									rounded
									onClick={() => downloadLinkRef?.current?.click()}
								>
									<AnchorLink
										to="/#coming-soon"
										title="Download App"
										stripHash
										className="focus:outline-none"
										gatsbyLinkProps={{
											tabIndex: -1,
											ref: downloadLinkRef
										}}
									>
										Download
									</AnchorLink>
								</Button>
							</li>
							<li className="link">
								<Link to="/join-our-team">Join our team</Link>
							</li>
						</div>
					</ul>
				</nav>
			</div>
			<HamburgerMenu open={menuVisible} setOpen={setMenuVisible} />
			<HamburgerMenuIconFixed open={menuVisible} setOpen={setMenuVisible} />
		</>
	)
}

import React, { useEffect, useRef } from 'react'
import cn from 'classnames'
import { Link } from 'gatsby'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { bool, func } from 'prop-types'

export default function HamburgerMenu({ open, setOpen }) {
	const [hamburgerMenuRef] = useRef(null)

	useEffect(() => {
		if (open) {
			document.body.classList.add('overflow-y-hidden')
			hamburgerMenuRef?.current?.focus()
		} else document.body.classList.remove('overflow-y-hidden')
	}, [hamburgerMenuRef, open])

	return (
		<nav
			className={cn('hamburger-menu', {
				open
			})}
			ref={hamburgerMenuRef}
		>
			<ul>
				<li className="link">
					<Link to="/" onClick={() => setOpen(false)}>
						Home
					</Link>
				</li>
				<li className="link">
					<AnchorLink
						to="/#features"
						title="Features"
						onAnchorLinkClick={() => setOpen(false)}
					/>
					{/* <Link to="/#features" onClick={() => setOpen(false)}>
						Features
					</Link> */}
				</li>
				<li className="link">
					<Link to="/faq" onClick={() => setOpen(false)}>
						FAQs
					</Link>
				</li>
				<li className="link">
					<AnchorLink
						to="/#feedback"
						title="Get in touch"
						onAnchorLinkClick={() => setOpen(false)}
					/>
					{/* <Link to="/#feedback" onClick={() => setOpen(false)}>
						Get in touch
					</Link> */}
				</li>
				<li className="link">
					<Link to="/join-our-team" onClick={() => setOpen(false)}>
						Join our team
					</Link>
				</li>
			</ul>
		</nav>
	)
}
HamburgerMenu.propTypes = {
	open: bool.isRequired,
	setOpen: func.isRequired
}

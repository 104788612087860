import React from 'react'
import cn from 'classnames'
import { bool, node, object, oneOf, string } from 'prop-types'

import './styles.scss'

export default function Button({
	className,
	style,
	fullWidth,
	children,
	variant,
	size,
	rounded,
	bgColor,
	color,
	defaultColors,
	...restProps
}) {
	return (
		<button
			type="button"
			className={cn(
				'btn relative overflow-hidden px-6 flex items-center justify-center outline-none shadow',
				{
					'leading-normal tracking-0.5 font-secondary font-normal': true,
					'text-white bg-teal-500 hover:bg-teal-600 focus:bg-teal-600 active:bg-teal-500 ': defaultColors,
					'hover:shadow-sm focus:shadow-sm active:shadow-none': true,
					'rounded-full': rounded,
					fancy: variant === 'fancy',
					'text-sm min-w-28 h-8': size === 'small',
					'text-lg min-w-52 h-10': size === 'big',
					'w-full': fullWidth
				},
				className
			)}
			style={{
				backgroundColor: `${bgColor} !important`,
				color: `${color} !important`,
				...style
			}}
			{...restProps}
		>
			{children}
		</button>
	)
}
Button.propTypes = {
	className: string,
	fullWidth: bool,
	children: node.isRequired,
	variant: oneOf(['flat', 'fancy']),
	size: oneOf(['small', 'big']),
	rounded: bool,
	bgColor: string,
	color: string,
	// eslint-disable-next-line react/forbid-prop-types
	style: object,
	defaultColors: bool
}
Button.defaultProps = {
	className: undefined,
	fullWidth: false,
	variant: 'flat',
	size: 'big',
	rounded: true,
	bgColor: undefined,
	color: undefined,
	style: undefined,
	defaultColors: true
}
export { Button }

import React from 'react'
import { Fade } from 'react-reveal'
import cn from 'classnames'
import { arrayOf, bool, node, string } from 'prop-types'

import Image1 from '../../assets/f1.png'
import Image2 from '../../assets/f2.png'
import Image3 from '../../assets/f3.png'

import './style.scss'

const FeatureCard = ({
	imageLeft,
	titleComponent,
	checkItems = [],
	image,
	subTitle,
	imageClassName
}) => {
	return (
		<div className="featureSection flex flex-col md:flex-row md:py-8">
			<Fade left={!imageLeft} right={imageLeft}>
				<div className="flex-1 self-center order-1 md:order-2 mb-10 md:mb-0">
					{titleComponent}
					<h4 className="my-3 font-secondary text-sm xl:text-base">
						{subTitle}
					</h4>
					<ul>
						{checkItems.map((item) => (
							<li key={item} className="text-xs xl:text-sm my-2 leading-normal">
								{item}
							</li>
						))}
					</ul>
				</div>
			</Fade>
			<Fade left={imageLeft} right={!imageLeft}>
				<div
					className={`flex-1 flex order-2 mb-16 md:mb-0 ${
						imageLeft ? 'md:order-1 md:mr-8' : 'md:order-2'
					}`}
				>
					<img
						className={cn(
							'h-full max-h-44 xl:max-h-52 w-auto mx-auto',
							imageClassName
						)}
						src={image}
						alt=""
					/>
				</div>
			</Fade>
		</div>
	)
}

FeatureCard.propTypes = {
	imageLeft: bool,
	titleComponent: node.isRequired,
	checkItems: arrayOf(string).isRequired,
	image: node.isRequired,
	subTitle: string.isRequired,
	imageClassName: string
}
FeatureCard.defaultProps = {
	imageLeft: false,
	imageClassName: undefined
}

export default function FeaturesSection({ className }) {
	return (
		<>
			<section className={cn(className)} name="features" id="features">
				<FeatureCard
					titleComponent={
						<h2 className="font-secondary text-xl xl:text-2xl">
							Helps your child <mark>learn and love Islam</mark>
						</h2>
					}
					subTitle="Fun, bite-sized lessons, with a gamified experience"
					checkItems={[
						'10 academic levels per subject',
						'5,000+ interactive micro-lessons, quizzes, stories and audio',
						'Learning gets rewarded with coins, gems and trophies',
						'Spaced repetition to review difficult lessons more often'
					]}
					image={Image1}
				/>
				<FeatureCard
					titleComponent={
						<h2 className="font-secondary text-xl xl:text-2xl">
							<mark>Safe</mark> and <mark>reliable</mark> learning
						</h2>
					}
					subTitle="Perfect for kids’ ages 7-14"
					checkItems={[
						'Content based on trusted Islamic studies books',
						'Content has been validated with Muslim scholars',
						'Content is structured in an age-appropriate manner',
						'Progressive system of learning'
					]}
					imageLeft
					image={Image2}
					imageClassName="max-h-48"
				/>
				<FeatureCard
					titleComponent={
						<h2 className="font-secondary text-xl xl:text-2xl">
							An enjoyable <mark>bonding</mark> routine between{' '}
							<mark>you and your child</mark>
						</h2>
					}
					subTitle="See what your child is learning and practice together"
					checkItems={[
						'Keep track of your child’s progress and learning by subject',
						'Set and see if your child has reached the weekly goal',
						'Talk regularly with your child about their learning',
						'Encourage your child by awarding gems for putting the learning into practice'
					]}
					image={Image3}
				/>
			</section>
		</>
	)
}
FeaturesSection.propTypes = {
	className: string
}
FeaturesSection.defaultProps = {
	className: undefined
}
export { FeaturesSection }

import React from 'react'
import { Fade } from 'react-reveal'
import cn from 'classnames'
import { Link } from 'gatsby'
import { string } from 'prop-types'

import ChatBubbleIcon from '../../assets/chat-bubble.inline.svg'
import bird from '../../assets/feedback-bird.png'
import BirdShadow from '../../assets/feedback-bird-shadow.svg'
import BirdMobileShadow from '../../assets/feedback-bird-shadow-mobile.svg'
import QuestionMarkIcon from '../../assets/question-mark.inline.svg'

import './styles.scss'

export default function FeedbackSection({ className }) {
	return (
		<section
			name="feedback"
			id="feedback"
			className={cn('feedback-section', className)}
		>
			<Fade left>
				<img
					src={bird}
					alt="A bird that encourages submitting feedback"
					className="w-48 h-auto mb-10 md:mb-0"
				/>
			</Fade>
			<img
				src={BirdShadow}
				className="hidden md:inline-block absolute"
				style={{ width: '243px', height: '235px', left: '-92px', top: '165px' }}
				alt="shadow"
			/>
			<img
				src={BirdMobileShadow}
				className="inline-block md:hidden absolute"
				style={{
					width: '347px',
					height: '260px',
					transform: 'translateX(-50%)',
					left: '50%'
				}}
				alt="shadow"
			/>
			<div className="flex flex-col w-full md:ml-16">
				<Fade>
					<h2 className="text-2xl md:text-3xl mb-6 text-center md:text-left font-secondary">
						We <mark className="text-pink-500 bg-transparent">love</mark>
						&nbsp;feedback!
					</h2>
				</Fade>
				<div className="flex flex-row flex-wrap justify-center md:justify-start">
					<div className="feedback-card">
						<QuestionMarkIcon className="w-32 h-20 mr-6" />
						<p className="text-sm leading-normal">
							If you have questions about the Deenee app,
							<br />
							<Link to="/faq" className="text-pink-500 font-secondary">
								check out our FAQ.
							</Link>
						</p>
					</div>
					<div className="feedback-card">
						<ChatBubbleIcon className="mr-6 h-16 w-36" />
						<p className="text-sm leading-normal">
							For general feedback and other inquiries?
							<br />
							<a
								href="mailto:help@deeneeapp.com"
								target="_blank"
								rel="noreferrer"
								className="text-pink-500 font-secondary"
							>
								Email us for help!
							</a>
						</p>
					</div>
				</div>
			</div>
		</section>
	)
}
FeedbackSection.propTypes = {
	className: string
}
FeedbackSection.defaultProps = {
	className: undefined
}

/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef } from 'react'
import { toast } from 'react-toastify'
import axios from 'axios'
import cn from 'classnames'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup'

import { Button } from '../button'

const API_URL = process.env.GATSBY_API_URL
const FILE_SIZE = 10485760 // 10MB

export default function SubmitApplicationForm() {
	const fileInputRef = useRef(null)

	return (
		<Formik
			initialValues={{
				name: '',
				email: '',
				// phone_number: '',
				role: '',
				cv: undefined,
				hearing: '',
				linkedin: '',
				additional_info: ''
			}}
			validationSchema={Yup.object({
				name: Yup.string().required('Required'),
				email: Yup.string().email('Invalid email address').required('Required'),
				role: Yup.string().required('Required'),
				// phone_number: Yup.string()
				// .required('Required')
				// .matches(
				// 	/^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/,
				// 	'Invalid phone number'
				// ),
				cv: Yup.mixed()
					.required('Required')
					.test(
						'fileSize',
						'File too large',
						(value) => value && value.size <= FILE_SIZE
					)
				// .test(
				//   "fileFormat",
				//   "Unsupported Format",
				//   value => value && SUPPORTED_FORMATS.includes(value.type)
				// )
			})}
			onSubmit={(values, { setSubmitting, resetForm }) => {
				const fd = new FormData()
				Object.entries(values).forEach(([key, value]) => fd.append(key, value))
				axios
					.post('/landing/application', fd, {
						baseURL: API_URL,
						timeout: 10000,
						headers: {
							'Content-Type': 'multipart/form-data;'
						}
					})
					.then(() => {
						resetForm()
						toast.success('Submitted', {
							position: 'top-left',
							autoClose: 6000,
							hideProgressBar: true,
							closeOnClick: false,
							pauseOnHover: true,
							draggable: true,
							progress: undefined
						})
					})
					.catch((err) => {
						toast.error(err?.message || 'Something went wrong', {
							position: 'top-left',
							autoClose: 5000,
							hideProgressBar: true,
							closeOnClick: false,
							pauseOnHover: true,
							draggable: true,
							progress: undefined
						})
					})
					.finally(() => setSubmitting(false))
			}}
		>
			{({ isSubmitting, setFieldValue, handleBlur, getFieldMeta, errors }) => (
				<Form id="submit-application-form" className="container font-secondary">
					<fieldset className="w-full flex flex-col items-center">
						<legend className="pt-4 mb-8 text-center font-bold text-2xl leading-snug">
							Submit your application
						</legend>
						<label htmlFor="name" className="w-full mb-3 flex flex-col">
							<span className="mb-1 font-bold">Name:</span>
							<Field
								type="text"
								name="name"
								id="name"
								className="input mb-1 w-full"
								autoFocus
							/>
							<ErrorMessage
								name="name"
								component="div"
								className="text-red-500"
							/>
						</label>

						<label htmlFor="email" className="w-full mb-3 flex flex-col">
							<span className="mb-1 font-bold">Email:</span>
							<Field
								type="text"
								name="email"
								id="email"
								className="input mb-1 w-full"
							/>
							<ErrorMessage
								name="email"
								component="div"
								className="text-red-500"
							/>
						</label>

						{/* <label htmlFor="phone_number" className="w-full mb-3 flex flex-col">
							<span className="mb-1 font-bold">Phone number:</span>
							<Field
								type="tel"
								name="phone_number"
								id="phone_number"
								className="input mb-1 w-full"
							/>
							<ErrorMessage
								name="phone_number"
								component="div"
								className="text-red-500"
							/>
						</label> */}

						<label htmlFor="role" className="w-full mb-3 flex flex-col">
							<span className="mb-1 font-bold">
								Which role are you interested in?
							</span>
							<Field
								name="role"
								id="role"
								as="select"
								className="input mb-1 w-full px-3"
							>
								<option defaultValue value="">
									Select a role
								</option>
								<option value="Digital marketing specialist">
									Digital marketing specialist
								</option>
								<option value="Social media specialist">
									Social media specialist
								</option>
								<option value="Copywriter">Copywriter</option>
								<option value="Storywriter">Storywriter</option>
								<option value="Voice over artist">Voice over artist</option>
								<option value="2D Animator / Motion Graphics Artist">
									2D Animator / Motion Graphics Artist
								</option>
								<option value="Illustrator">Illustrator</option>
								<option value="Content developers">Content developer</option>
							</Field>
							<ErrorMessage
								name="role"
								component="div"
								className="text-red-500"
							/>
						</label>

						<label htmlFor="cv" className="w-full mb-3 flex flex-col">
							<span className="mb-1 font-bold">Attach your CV</span>
							<input
								ref={fileInputRef}
								className="hidden"
								type="file"
								onChange={(e) =>
									setFieldValue('cv', e.currentTarget?.files[0], true)
								}
							/>
							<button
								onClick={() => fileInputRef.current?.click()}
								id="cv"
								name="cv"
								type="button"
								className="input mb-1 w-full flex items-center py-1 focus:border-purple-900 outline-none"
								onBlur={handleBlur}
							>
								{getFieldMeta('cv').value?.name || 'Choose File'}
							</button>
							<div className="text-red-500">{errors.cv}</div>
						</label>

						<label htmlFor="hearing" className="w-full mb-3 flex flex-col">
							<span className="mb-1 font-bold">
								How did you hear about deenee?
							</span>
							<Field
								type="text"
								name="hearing"
								id="hearing"
								className="input mb-1 w-full"
							/>
							<ErrorMessage
								name="hearing"
								component="div"
								className="text-red-500"
							/>
						</label>

						<label htmlFor="linkedin" className="w-full mb-3 flex flex-col">
							<span className="mb-1 font-bold">
								If you are on linkedin, please provide your profile link
							</span>
							<Field
								type="text"
								name="linkedin"
								id="linkedin"
								className="input mb-1 w-full"
							/>
						</label>

						<label
							htmlFor="additional_info"
							className="w-full mb-3 flex flex-col"
						>
							<span className="mb-1 font-bold">Additional information</span>
							<Field
								as="textarea"
								name="additional_info"
								id="additional_info"
								className="input mb-1 w-full rounded-sm placeholder-gray-500 h-auto"
								rows={4}
								placeholder="Add additional notes or anything else you'd like to share"
							/>
						</label>

						<Button
							type="submit"
							className={cn('mx-auto mt-4', {
								'cursor-wait': isSubmitting
							})}
							disabled={isSubmitting}
							// fullWidth
							variant="fancy"
						>
							Submit application
						</Button>
					</fieldset>
				</Form>
			)}
		</Formik>
	)
}

export { SubmitApplicationForm as SumitApplicationForm }

/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react'
import { toast } from 'react-toastify'
import axios from 'axios'
import cn from 'classnames'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup'

import { Button } from '../button'

import './styles.scss'

const API_KEY = process.env.GATSBY_SENDGRID_API_KEY
const CONTACTS_LIST_ID = process.env.GATSBY_NEWSLETTER_CONTACTS_LIST_ID

export default function NewsletterForm() {
	useEffect(() => {
		if (!API_KEY || !CONTACTS_LIST_ID) {
			throw new Error(
				'Sendgrid API key or newsletter contacts list ID not set, please refer to README.md file'
			)
		}
	}, [])

	return (
		<Formik
			initialValues={{
				first_name: '',
				email: ''
			}}
			validationSchema={Yup.object({
				first_name: Yup.string().required('Required'),
				email: Yup.string().email('Invalid email address').required('Required')
			})}
			onSubmit={(values, { setSubmitting, resetForm }) => {
				if (API_KEY && CONTACTS_LIST_ID) {
					axios
						.put(
							'https://api.sendgrid.com/v3/marketing/contacts',
							{
								list_ids: [CONTACTS_LIST_ID],
								contacts: [values]
							},
							{
								headers: {
									authorization: `Bearer ${API_KEY}`,
									'content-type': 'application/json'
								}
							}
						)
						.then(() => {
							resetForm()
							toast.success('Subscribed', {
								position: 'top-left',
								autoClose: 6000,
								hideProgressBar: true,
								closeOnClick: false,
								pauseOnHover: true,
								draggable: true,
								progress: undefined
							})
						})
						.catch((err) => {
							toast.error(err.message || 'something went wrong', {
								position: 'top-left',
								autoClose: 5000,
								hideProgressBar: true,
								closeOnClick: false,
								pauseOnHover: true,
								draggable: true,
								progress: undefined
							})
						})
						.finally(() => setSubmitting(false))
				} else {
					//
				}
			}}
		>
			{({ isSubmitting, errors, touched }) => (
				<Form
					id="subscribe-newsletter-form"
					className="newsletter-form py-8 h-96 w-full lg:w-92 xl:w-96 lg:h-full flex flex-col items-center justify-center text-center lg:items-end lg:justify-start lg:px-12"
				>
					<fieldset>
						<legend className="text-purple-100 mb-4 text-base leading-5 font-normal font-secondary">
							SUBSCRIBE TO
							<br />
							OUR NEWSLETTER!
						</legend>
						<label
							htmlFor="first_name"
							className={cn('w-full flex flex-col items-start mb-2', {
								'mb-0.5': errors.name && touched.name
							})}
						>
							<Field
								type="text"
								name="first_name"
								id="first_name"
								placeholder="Name"
								className="input"
							/>
							<ErrorMessage
								name="first_name"
								component="div"
								className="text-red-500 text-sm"
							/>
						</label>
						<label
							htmlFor="email"
							className={cn('w-full flex flex-col items-start mb-2', {
								'mb-0.5': errors.email && touched.email
							})}
						>
							<Field
								type="text"
								name="email"
								id="email"
								placeholder="Email"
								className="input"
							/>
							<ErrorMessage
								name="email"
								component="div"
								className="text-red-500 text-sm"
							/>
						</label>
						<Button
							type="submit"
							className={cn('w-52 focus:ring focus:ring-blue-500', {
								'cursor-wait': isSubmitting
							})}
							rounded
							disabled={isSubmitting}
						>
							Subscribe
						</Button>
					</fieldset>
				</Form>
			)}
		</Formik>
	)
}
export { NewsletterForm }

import React from 'react'
import cn from 'classnames'
import { bool, node, string } from 'prop-types'

import './styles.scss'

export default function DownloadFromStore({
	icon,
	downloadText,
	downloadFrom,
	downloadLink,
	wrapperClassName,
	className,
	comingSoonLabel,
	...restProps
}) {
	return (
		<a
			href={downloadLink}
			target="_blank"
			rel="noreferrer"
			className={cn(
				'outline-none inline-block rounded-md overflow-hidden relative cursor-pointer',
				wrapperClassName
			)}
			{...restProps}
		>
			<div
				className={cn(
					'py-0.5 px-1 h-9 w-32 box-content flex flex-row flex-nowrap items-center justify-center rounded-md border border-current',
					{ 'coming-soon-label': comingSoonLabel },
					className
				)}
			>
				{icon}
				<h4 className="flex flex-col items-start justify-center text-current ml-2">
					<span className="leading-none mb-1" style={{ fontSize: '9px' }}>
						{downloadText}
					</span>
					<span className="text-sm font-medium leading-none">
						{downloadFrom}
					</span>
				</h4>
			</div>
			{comingSoonLabel && (
				<span
					className="absolute left-1 pr-1 top-0 w-full text-sm flex items-center justify-end bg-gradient-to-r primary-gradient text-purple-100 border border-white"
					style={{ transform: 'rotate(15deg)' }}
				>
					Coming soon
				</span>
			)}
		</a>
	)
}

DownloadFromStore.propTypes = {
	icon: node.isRequired,
	downloadFrom: string.isRequired,
	downloadText: string.isRequired,
	downloadLink: string.isRequired,
	wrapperClassName: string,
	className: string,
	comingSoonLabel: bool
}
DownloadFromStore.defaultProps = {
	wrapperClassName: undefined,
	className: undefined,
	comingSoonLabel: false
}

export { DownloadFromStore }

import React from 'react'
import cn from 'classnames'
import { bool, number, string } from 'prop-types'

import './style.scss'

export default function StarRatebar({ rate, className, yellow }) {
	return (
		<span className={cn('inline-flex', className)}>
			<span
				className={cn('stars-white h-5 inline-block', {
					'stars-yellow': yellow
				})}
				style={{ width: 23 * rate }}
			/>
		</span>
	)
}
StarRatebar.propTypes = {
	rate: number.isRequired,
	className: string,
	yellow: bool
}
StarRatebar.defaultProps = {
	className: '',
	yellow: false
}
export { StarRatebar }

import React, { useEffect, useState } from 'react'
import { Fade } from 'react-reveal'
import cn from 'classnames'
import { Link } from 'gatsby'
import { string } from 'prop-types'

import AppleIcon from '../../assets/apple-icon.inline.svg'
import GooglePlayIcon from '../../assets/google-play-colored-icon.inline.svg'
import PlayIcon from '../../assets/play.inline.svg'
import PlayBtnBg from '../../assets/play-btn-bg.inline.svg'
import { DownloadFromStore } from '../download-from-store'
import { StarRatebar } from '../star-ratebar'
import { VideoModal } from '../video-modal'

import './styles.scss'

export default function HeaderSection({ className }) {
	const [modalIsVisible, setModalVisible] = useState(false)

	useEffect(() => {
		if (modalIsVisible) document.body.classList.add('overflow-y-hidden')
		else document.body.classList.remove('overflow-y-hidden')
	}, [modalIsVisible])

	return (
		<>
			<header className={cn('header-root', className)}>
				<div className="inner flex flex-col md:flex-row items-center container 2xl:px-0">
					<div className="md:h-full order-2 md:order-1 text-center md:text-left w-full md:max-w-md flex flex-col justify-start items-center md:items-start md:justify-center">
						<h1 className="text-white flex flex-col md:pt-14">
							<Fade>
								<span
									id="coming-soon"
									className="header-title mb-1.5 md:mb-2"
								/>
								<span className="header-subtitle text-lg leading-6 md:text-xl break-words mb-6 md:mb-9 md:px-0 font-secondary">
									{/* helps your child learn and love Islam through fun bite-sized
									lessons, teaching all the Islamic essentials. */}
									The all-in-one Islamic education app for children
								</span>
							</Fade>
						</h1>
						<div className="inline-flex flex-col w-max items-center justify-center md:items-start">
							<span className="font-secondary text-purple-100 mb-3 md:mb-4 text-sm md:text-base">
								Download now
							</span>
							<div className="flex items-center justify-center md:justify-start mb-3">
								<DownloadFromStore
									icon={
										<GooglePlayIcon
											className="fill-current inline"
											style={{ width: 22, height: 24 }}
										/>
									}
									downloadText="GET IT ON"
									downloadFrom="Google Play"
									downloadLink="https://play.google.com/store/apps/details?id=eu.weblast.deenee&hl=en&gl=US"
									wrapperClassName="text-white hover:bg-white hover:text-purple-300 focus:bg-white focus:text-purple-300 mr-3"
									// comingSoonLabel
									className="xl:w-36"
								/>
								<DownloadFromStore
									icon={<AppleIcon className="fill-current inline w-5 h-6" />}
									downloadText="Download on the"
									downloadFrom="App Store"
									downloadLink="https://apps.apple.com/gb/app/deenee-islam-for-kids/id1550786854"
									wrapperClassName="text-white hover:bg-white hover:text-purple-300 focus:bg-white focus:text-purple-300"
									// comingSoonLabel
									className="xl:w-36"
								/>
							</div>
							<p className="text-purple-100 flex text-xs xl:text-sm leading-6 items-center justify-center md:justify-start mb-4 md:mb-12">
								<span>Rated</span>
								<StarRatebar rate={5} className="mx-2" />
								<span>by 10K+ families</span>
							</p>
						</div>
					</div>
					<div className="play-btn-container w-full md:h-full order-1 md:order-2 flex items-start md:items-end justify-center">
						<button
							type="button"
							onClick={() => setModalVisible(true)}
							className="play-btn w-20 h-20 md:w-28 md:h-28 relative"
						>
							<Link
								to="/"
								className="outline-none text-purple-500 hover:text-white focus:text-white fill-white hover:fill-purple-500 focus:fill-purple-500"
							>
								<PlayIcon className="absolute w-7 h-7 md:w-9 md:h-9 left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 fill-current stroke-current" />
								<PlayBtnBg className="play-btn-bg w-full h-full fill-inherit" />
							</Link>
						</button>
					</div>
				</div>
				<VideoModal visible={modalIsVisible} setVisible={setModalVisible} />
			</header>
		</>
	)
}
HeaderSection.propTypes = {
	className: string
}
HeaderSection.defaultProps = {
	className: undefined
}

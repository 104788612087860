import React from 'react'
import Modal from 'react-modal'
import Player from 'react-player'
import { bool, func } from 'prop-types'

export default function VideoModal({ visible, setVisible }) {
	return (
		<Modal
			isOpen={visible}
			onRequestClose={() => setVisible(false)}
			shouldCloseOnOverlayClick
			overlayClassName="z-40 fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-75"
			className="z-40 absolute bg-black focus:outline-none top-10 left-10 right-10 bottom-10"
			preventScroll
		>
			<Player
				url="https://youtu.be/V2Jepfd7Thw"
				playing
				width="100%"
				height="100%"
			/>
		</Modal>
	)
}
VideoModal.propTypes = {
	visible: bool.isRequired,
	setVisible: func.isRequired
}

export { VideoModal }

import React from 'react'
import { bool, node } from 'prop-types'

import Footer from '../footer'
import Navbar from '../navbar'

export default function MainLayout({ children, hasNavbar, hasFooter }) {
	return (
		<>
			{hasNavbar && <Navbar />}
			<div className="pt-20 mx-auto min-h-screen">{children}</div>
			{hasFooter && <Footer />}
		</>
	)
}
MainLayout.propTypes = {
	children: node.isRequired,
	hasNavbar: bool,
	hasFooter: bool
}
MainLayout.defaultProps = {
	hasNavbar: true,
	hasFooter: true
}
export { MainLayout }

import React from 'react'
import { Link } from 'gatsby'

import shadow2 from '../../assets/join-our-team-shadow-2.svg'
import { Button } from '../button'

import './styles.scss'

export default function RolesSection() {
	return (
		<section className="roles-section">
			<h3 className="section-heading primary-gradient">
				Want to join the Deenee team? We are looking for:
			</h3>
			<ul className="roles-list">
				<li className="role-card">
					<span className="role-title">Digital marketing specialists</span>
					&nbsp;to build the community and brand
				</li>
				<li className="role-card">
					<span className="role-title">Social media specialists</span>
					&nbsp;to develop and maintain our social media channels
				</li>
				<li className="role-card">
					<span className="role-title">Copywriters</span>
					&nbsp;to develop newsletters to parents
				</li>
				<li className="role-card">
					<span className="role-title">Storywriters</span>
					&nbsp;to develop new stories to inspire kids
				</li>
				<li className="role-card">
					<span className="role-title">Voice over artists</span>
					&nbsp;to record audios of stories and animations
				</li>
				<li className="role-card">
					<span className="role-title">
						2D Animators / Motion Graphics Artists
					</span>
					&nbsp;to animate our content
				</li>
				<li className="role-card">
					<span className="role-title">Illustrators</span>
					&nbsp;to add more imagery that can inspire kids
				</li>
				<li className="role-card">
					<span className="role-title">Content developers</span>
					&nbsp;to develop and add new educational content
				</li>
				<img src={shadow2} className="bg-shadow" alt="shadow" />
			</ul>
			<p className="footnote primary-gradient">
				Please do get in touch with us if you have the passion and talent that
				matches one of the roles above.
			</p>
			<Link
				to="/submit-application"
				className="mx-auto inline-flex mb-24 max-w-80 w-full"
				tabIndex={-1}
			>
				<Button className="cta-btn" defaultColors={false}>
					Join the team
				</Button>
			</Link>
		</section>
	)
}

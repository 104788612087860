import React from 'react'
import cn from 'classnames'
import { bool, func } from 'prop-types'

export default function HamburgerMenuIcon({ open, setOpen }) {
	return (
		<button
			type="button"
			className={cn('hamburger-menu-icon', { open })}
			open={open}
			onClick={() => setOpen((prevState) => !prevState)}
		>
			<div />
			<div />
			<div />
		</button>
	)
}
HamburgerMenuIcon.propTypes = {
	open: bool.isRequired,
	setOpen: func.isRequired
}

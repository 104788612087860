import React from 'react'
import { Link } from 'gatsby'

import AppleIcon from '../../assets/apple-icon.inline.svg'
import FacebookIcon from '../../assets/facebook.inline.svg'
import IconLinkBg from '../../assets/footer-icon-link-bg.inline.svg'
import GooglePlayIcon from '../../assets/google-play-icon.inline.svg'
import InstagramIcon from '../../assets/instagram.inline.svg'
// import TwitterIcon from '../../assets/twitter.inline.svg'
import YoutubeIcon from '../../assets/youtube.inline.svg'
import { DownloadFromStore } from '../download-from-store'
import { NewsletterForm } from '../newsletter-form'

import './styles.scss'

export default function Footer() {
	return (
		<footer className="footer-root">
			<div className="w-full flex flex-col lg:flex-row lg:flex-wrap">
				<div className="container lg:pr-0 flex flex-row items-start justify-center lg:justify-evenly flex-wrap flex-1">
					<ul className="pt-12 mr-3.5 lg:mr-12 flex flex-col items-center text-center lg:text-left lg:items-start">
						<h3 className="text-purple-300 mb-4 text-sm xl:text-base">
							Download Deenee
						</h3>
						<li>
							<DownloadFromStore
								icon={
									<GooglePlayIcon
										className="fill-current inline"
										style={{ width: 27, height: 30 }}
									/>
								}
								downloadText="GET IT ON"
								downloadFrom="Google Play"
								downloadLink="https://play.google.com/store/apps/details?id=eu.weblast.deenee&hl=en&gl=US"
								wrapperClassName="text-purple-100 hover:bg-purple-100 hover:text-purple-900 focus:bg-purple-100 focus:text-purple-900 mb-4"
								// className="w-36"
								// comingSoonLabel
							/>
						</li>
						<li>
							<DownloadFromStore
								icon={
									<AppleIcon
										className="fill-current inline"
										style={{ width: 24, height: 30 }}
									/>
								}
								downloadText="Download on the"
								downloadFrom="App Store"
								downloadLink="https://apps.apple.com/gb/app/deenee-islam-for-kids/id1550786854"
								wrapperClassName="text-purple-100 hover:bg-purple-100 hover:text-purple-900 focus:bg-purple-100 focus:text-purple-900"
								// className="w-36"
								// comingSoonLabel
							/>
						</li>
					</ul>
					<ul className="pt-12 md:pb-8 ml-3.5 lg:ml-0 lg:mr-12 flex flex-col items-center text-center lg:text-left lg:items-start">
						<h3 className="text-purple-300 mb-4 text-sm xl:text-base">
							Support
						</h3>
						<div className="xl:text-lg">
							<li className="footer-link">
								<Link to="/faq">FAQs</Link>
							</li>
							<li className="footer-link">
								<Link to="/terms-of-service">Terms of service</Link>
							</li>
							<li className="footer-link">
								<Link to="/privacy-policy">Privacy Policy</Link>
							</li>
						</div>
					</ul>
					<ul className="pt-12 md:pb-8 flex-item-full lg:flex-initial flex flex-col items-center text-center lg:text-left lg:items-start">
						<h3 className="text-purple-300 mb-4 text-sm xl:text-base">
							We are social
						</h3>
						<div className="flex flex-row items-center justify-center">
							<li className="icon-link">
								<a
									href="https://www.instagram.com/deeneeapp/"
									target="_blank"
									rel="noreferrer"
								>
									<InstagramIcon className="icon" />
									<IconLinkBg className="icon-bg" />
								</a>
							</li>
							{/* <li className="icon-link">
								<a
									href="https://twitter.com/deeneeapp"
									target="_blank"
									rel="noreferrer"
								>
									<TwitterIcon className="icon" />
									<IconLinkBg className="icon-bg" />
								</a>
							</li> */}
							<li className="icon-link">
								<a
									href="https://www.facebook.com/Deeneeapp-109504861192334"
									target="_blank"
									rel="noreferrer"
								>
									<FacebookIcon className="icon" />
									<IconLinkBg className="icon-bg" />
								</a>
							</li>
							<li className="icon-link">
								<a
									href="https://www.youtube.com/channel/UCVa1dyWUnK01JernY2xS-uw"
									target="_blank"
									rel="noreferrer"
								>
									<YoutubeIcon className="icon" />
									<IconLinkBg className="icon-bg" />
								</a>
							</li>
						</div>
					</ul>
				</div>
				<NewsletterForm />
			</div>
		</footer>
	)
}
